// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookiebeleid-tsx": () => import("./../../src/pages/cookiebeleid.tsx" /* webpackChunkName: "component---src-pages-cookiebeleid-tsx" */),
  "component---src-pages-diensten-tsx": () => import("./../../src/pages/diensten.tsx" /* webpackChunkName: "component---src-pages-diensten-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-projecten-arno-poppelaars-tsx": () => import("./../../src/pages/projecten/arno-poppelaars.tsx" /* webpackChunkName: "component---src-pages-projecten-arno-poppelaars-tsx" */),
  "component---src-pages-projecten-gelatoqueen-tsx": () => import("./../../src/pages/projecten/gelatoqueen.tsx" /* webpackChunkName: "component---src-pages-projecten-gelatoqueen-tsx" */),
  "component---src-pages-projecten-samtrans-tsx": () => import("./../../src/pages/projecten/samtrans.tsx" /* webpackChunkName: "component---src-pages-projecten-samtrans-tsx" */),
  "component---src-pages-projecten-tomaten-de-maeyer-tsx": () => import("./../../src/pages/projecten/tomaten-de-maeyer.tsx" /* webpackChunkName: "component---src-pages-projecten-tomaten-de-maeyer-tsx" */),
  "component---src-pages-projecten-tsx": () => import("./../../src/pages/projecten.tsx" /* webpackChunkName: "component---src-pages-projecten-tsx" */)
}

